import React                from "react"
import PropTypes            from "prop-types"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import Layouter   from 'layouter'

import TopWall    from './TopWall'
import LeftWall   from './LeftWall'
import RightWall  from './RightWall'
import Popup      from './Popup'

class House extends React.Component {

  constructor(props){
    super();

    // create an array of all projects out of the nested structure
    this.flatProjects = [].concat.apply([], Object.values(props.windows)).reduce(
      (acc, val) => acc.concat(val.projects), []
    );

    // preload all project images
    this.flatProjects.forEach((p) => {
      p.image_urls.map((url) => {
        var i = new Image(); i.src=url;
      })
    });

    this.state = {
      highlightLabel: null,
      highlightTiles: null
    }
  }

  findProjectBySlug (slug) {
    return this.flatProjects.find((project) => project.slug == slug)
  }

  handleProjectHover = (id) => {
    this.setState((state, props) => {
      return {highlightLabel: id}
    })
  }

  handleCategoryHover = (id) => {
    this.setState((state, props) => {
      return {highlightTiles: id}
    })
  }

  render () {
    let leftProjectItems, rightProjectItems, rightImprintItems, rightChronicleItems, topOfficeItems, topChronicleItems;

    const projectItems = this.props.windows.projects.map((group) => {
      return [{label: [group.name, group.width], id: group.id, path: group.path}].concat(group.projects)
    });

    const officeItems = this.props.windows.office.map((group) => {
      return group.projects.concat([{label: [group.name, group.width], id: group.id, path: group.path}]);
    });

    const imprintItems = this.props.windows.imprint.map((group) => {
      return group.projects.concat([{label: [group.name, group.width], id: group.id, path: group.path}]);
    });

    const chronicleItems = this.props.windows.chronicle.map((group) => {
      return group.projects;
    });

    [leftProjectItems, rightProjectItems]     = (new Layouter(6,  6      )).layout(projectItems);
    [topOfficeItems]                          = (new Layouter(8,  4      )).layout(officeItems);
    [rightImprintItems]                       = (new Layouter(10, 1      )).layout(imprintItems);

    [topChronicleItems, rightChronicleItems]  = (new Layouter(4, 10, true)).layout(chronicleItems);

    rightChronicleItems = rightChronicleItems.concat([[0,  1, {label: ['Chronik']}]]);
    leftProjectItems    =    leftProjectItems.concat([[0, -5, {label: ['Referenzen:']}]]);

    return (
      <Router basename='/'>
        <Route path={["/projects/:slug", "/"]} render={(mainRouter) => {
          const project = this.findProjectBySlug(mainRouter.match.params.slug);
          return (
            <>
              <TopWall    officeItems         = {topOfficeItems}
                          chronicleItems      = {topChronicleItems}
                          houseState          = {this.state}
                          handleProjectHover  = {this.handleProjectHover}
                          handleCategoryHover = {this.handleCategoryHover}
                          currentProject      = {project}
                          categoryId          = {project ? project.project_category_id : null}
              />

              <LeftWall   projectItems        = {leftProjectItems}
                          houseState          = {this.state}
                          handleProjectHover  = {this.handleProjectHover}
                          handleCategoryHover = {this.handleCategoryHover}
                          currentProject      = {project}
                          categoryId          = {project ? project.project_category_id : null}
              />

              <RightWall  projectItems        = {rightProjectItems}
                          imprintItems        = {rightImprintItems}
                          chronicleItems      = {rightChronicleItems}
                          houseState          = {this.state}
                          handleProjectHover  = {this.handleProjectHover}
                          handleCategoryHover = {this.handleCategoryHover}
                          currentProject      = {project}
                          categoryId          = {project ? project.project_category_id : null}
              />

              <Route exact path="/projects/:slug" render={ (slugRouter) => {
                return (<Popup
                   title={project.name}
                  images={project.image_urls}
                    text={project.description_formatted}
                   width={/(impressum|imprint)/.test(project.slug) ? 'wide' : 'narrow' }
                />);
              }} />
            </>
          );
        }} />
      </Router>
    );
  }
}

House.propTypes = {
  data: PropTypes.object
};

export default House
