import React      from "react"
import PropTypes  from "prop-types"
import classnames from 'classnames'
import {
  Link
} from "react-router-dom"

import GeometryContext from 'GeometryContext'

class Label extends React.Component {
  static contextType = GeometryContext;

  constructor(props) {
    super();
  }

  style = () => {
    return {left: this.props.pos[0] * this.context.windowWidth, top: this.props.pos[1] * this.context.windowHeight }
  }

  className = () => {
    const hl = this.props.houseState.highlightLabel;
    const hc = this.props.highCategoryId;
    const hm = this.props.highMetaCategoryId;

    return classnames(
      'label',
      {
        'active': (hl && this.props.categoryId      == hl) ||
                  (hc && this.props.categoryId      == hc) ||
                  (hm && this.props.metaCategoryId  == hm)

      }
    )
  }

  render () {
    return (
      <div className={this.className()} style={this.style()} onMouseEnter={() => this.props.handleHover(this.props.categoryId)} onMouseLeave={() => this.props.handleHover(null)}>
        <Link to={this.props.path || ''}>
          {this.props.text}
        </Link>
      </div>
    );
  }
}

Label.propTypes = {
  pos:          PropTypes.array.isRequired,
  text:         PropTypes.string.isRequired,
  categoryId:   PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

Label.defaultProps = {
  handleMouseEnter: () => {},
  handleMouseLeave: () => {},
}


export default Label
