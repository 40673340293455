import React from "react"
import PropTypes from "prop-types"
import WindowCanvas from './WindowCanvas'
import {
  Link
} from "react-router-dom"

import Wall   from './Wall'

import { useLocation } from 'react-router-dom';

class TopWall extends React.Component {
  render () {
    return (
      <Wall classnames={['wall-top']}>
        <WindowCanvas items               = {this.props.officeItems}
                      houseState          = {this.props.houseState}
                      xpos                = 'left'
                      ypos                = 'bottom'
                      handleProjectHover  = {this.props.handleProjectHover}
                      handleCategoryHover = {this.props.handleCategoryHover}
                      currentProject      = {this.props.currentProject}
                      categoryId          = {this.props.categoryId}
        />

        <WindowCanvas items               = {this.props.chronicleItems}
                      houseState          = {this.props.houseState}
                      xpos                = 'left'
                      ypos                = 'bottom'
                      handleProjectHover  = {this.props.handleProjectHover}
                      handleCategoryHover = {this.props.handleCategoryHover}
                      currentProject      = {this.props.currentProject}
                      categoryId          = {this.props.categoryId}
                      padding             = {[-4,0,0,10]}
        />
      </Wall>
    );
  }
}

TopWall.propTypes = {
  data: PropTypes.object
};

export default TopWall
