import React      from "react"
import PropTypes  from "prop-types"
import Slider     from 'react-slick'
import {
  Link
} from "react-router-dom"

import classnames from 'classnames'
import { withRouter } from "react-router-dom"


import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class Popup extends React.Component {
  className = () => classnames('popup', this.props.width)

  onClick = (e) => {
    const { match, location, history } = this.props;

    if(e.targetTagname == 'a') {
      e.preventDefault();
      window.location = e.href;
    } else {
      history.push("/projects");
    }
  }

  render () {
    const settings = {
      fade:           true,
      arrows:         false,
      dots:           false,
      autoplay:       true,
      autoplaySpeed:  3000
    };

    return (
      <div className={this.className()}>
        <Link to="/projects">
          {(() => {
            switch(this.props.images.length) {
              case 0:
                return null;
              case 1:
                return <img src={this.props.images[0]} />
              default:
                return (<Slider {...settings} >
                  {this.props.images.map((image, idx) => <div key={idx}><img src={image} /></div>)}
                </Slider>);
          }})() }
          <h1>{this.props.title}</h1>
        </Link>
        <p dangerouslySetInnerHTML={{__html: this.props.text}} onClick={this.onClick} />
      </div>
    );
  }
}
const PopupWithRouter = withRouter(Popup);
export default PopupWithRouter
