import React            from "react"
import PropTypes        from "prop-types"
import classnames       from 'classnames'

import Label            from './Label'
import Tile             from './Tile'
import GeometryContext  from 'GeometryContext'

class WindowCanvas extends React.Component {
  static contextType = GeometryContext;

  constructor(props) {
    super();
    // count items with label attribute (labels)
    const lines = props.items.filter(i => i[2].label );
    this.height = props.height || lines.length;
  }

  classNames() {
    return classnames('windowcanvas', `xpos-${this.props.xpos}`, `ypos-${this.props.ypos}` )
  };

  style() {
    return {width: this.props.width * this.context.windowWidth, height: this.height * this.context.windowHeight};
  }

  render () {
    return (
      <div className={this.classNames()} style={this.style()}>
        {
          this.props.items.map((item, idx) => {
            const pos     = [item[1] + this.props.padding[3], item[0] + this.props.padding[0]];
            const project = item[2];
            if (project.label) {
              return <Label key             = {idx}
                            pos             = {pos}
                            path            = {project.path}
                            text            = {project.label[0]}
                            categoryId      = {project.id}
                            handleHover     = {this.props.handleCategoryHover}
                            houseState      = {this.props.houseState}
                            highCategoryId  = {this.props.categoryId}
                />;
            } else {
              return <Tile  key             = {idx}
                            pos             = {pos}
                            projectId       = {project.id}
                            path            = {project.path}
                            categoryId      = {project.project_category_id}
                            handleHover     = {this.props.handleProjectHover}
                            houseState      = {this.props.houseState}
                            currentProject  = {this.props.currentProject}
                />;
            }
          })
        }
      </div>
    );
  }
}

WindowCanvas.propTypes = {
  width:    PropTypes.number,
  height:   PropTypes.number,
  xpos:     PropTypes.string,
  ypos:     PropTypes.string,
  items:    PropTypes.array,
  padding:  PropTypes.arrayOf(PropTypes.number)
};

WindowCanvas.defaultProps = {
  xpos:     'left',
  ypos:     'top',
  width:    6,
  //         t r b l
  padding:  [0,0,0,0],
};

export default WindowCanvas
