export default class Layouter {
  constructor(width1, width2, rotate=false){
    this.width1  = width1;
    this.width2  = width2;
    this.rotate  = rotate;
  }

  fullWidth() {
    return this.width1 + this.width2;
  }

  layout(projectGroups) {
    var x = 0;
    var y = 0;
    const items1 = [], items2 = [];

    projectGroups.forEach((projectGroup) => {

      if (!this.rotate){
        projectGroup.forEach((item) => {
          const width = item.label ? item.label[1] : 1;
          if        (x + width <= this.width1) {
            items1.push([y,x, item]);
            x += width;
          } else if (x + width <= this.fullWidth()) {
            items2.push([y,x - this.width1, item]);
            x += width;
          } else {
            x = 0;
            y += 1;
            items1.push([y,x, item]);
            x += width;
          }
        })
        x = 0
        y += 1;
      } else {
        projectGroup.forEach((item) => {
          if (y+1 <= this.width1) {
            items1.push([y,x, item]);
          } else {
            items2.push([y-this.width1,x, item]);
          }
          x = 0
          y += 1;
        })
      }
    });
    return [ items1, items2 ];
  }
}
