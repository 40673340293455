import React from "react"
import PropTypes from "prop-types"
import classnames from 'classnames'
import {
  Link
} from "react-router-dom"

class Wall extends React.Component {
  render () {
    return (
      <div className={classnames("wall", this.props.classnames || [])}>
        <Link to="/projects" className="plaster" />
        {this.props.children}
      </div>
    );
  }
}

Wall.propTypes = {
  data: PropTypes.object
};

export default Wall
