import React from "react"
import PropTypes from "prop-types"
import {
  Link
} from "react-router-dom"

import Wall         from './Wall'
import WindowCanvas from './WindowCanvas'

class RightWall extends React.Component {
  render () {
    return (
      <Wall classnames={['wall-right']}>
        <WindowCanvas items               = {this.props.projectItems}
                      houseState          = {this.props.houseState}
                      padding             = {[1,0,0,0]}
                      handleProjectHover  = {this.props.handleProjectHover}
                      handleCategoryHover = {this.props.handleCategoryHover}
                      currentProject      = {this.props.currentProject}
                      categoryId          = {this.props.categoryId}
        />

        <WindowCanvas items               = {this.props.imprintItems}
                      houseState          = {this.props.houseState}
                      padding             = {[10,0,0,10]}
                      handleProjectHover  = {this.props.handleProjectHover}
                      handleCategoryHover = {this.props.handleCategoryHover}
                      currentProject      = {this.props.currentProject}
                      categoryId          = {this.props.categoryId}
        />

        <WindowCanvas items               = {this.props.chronicleItems}
                      houseState          = {this.props.houseState}
                      handleProjectHover  = {this.props.handleProjectHover}
                      handleCategoryHover = {this.props.handleCategoryHover}
                      currentProject      = {this.props.currentProject}
                      categoryId          = {this.props.categoryId}
                      padding             = {[0,0,0,10]}
        />

      </Wall>
    );
  }
}

RightWall.propTypes = {
  data:               PropTypes.object,
  handleProjectHover: PropTypes.func
};

export default RightWall
