import React from "react"
import PropTypes from "prop-types"
import classnames from 'classnames'
import {
  Link
} from "react-router-dom"

import GeometryContext from 'GeometryContext'

class Tile extends React.Component {
  static contextType = GeometryContext;

  constructor(props) {
    super();
  }

  style = () => {
    return {left: this.props.pos[0] * this.context.windowWidth, top: this.props.pos[1] * this.context.windowHeight }
  }

  className = () => {
    return classnames('tile', {'active':  (this.props.categoryId == this.props.houseState.highlightTiles) || 
                                          (this.props.currentProject && (this.props.projectId == this.props.currentProject.id))})
  }

  render () {
    return (
      <div className={this.className()} style={this.style()} onMouseEnter={() => this.props.handleHover(this.props.categoryId)} onMouseLeave={() => this.props.handleHover(null)}>
        <Link to={this.props.path}>
          <div className="window" data-path={this.props.path} />
        </Link>
      </div>
    );
  }
}

Tile.propTypes = {
  pos:              PropTypes.array.isRequired,
  categoryId:       PropTypes.number,
  handleMouseEnter: PropTypes.func,
};

Tile.defaultProps = {
  handleMouseEnter: () => {},
}

export default Tile
